import $ from "jquery"

const drawerButton = $("#navigation_menu-check")
const drawer = $("#drawer")

drawerButton.on("click", function() {
  if (drawer.hasClass("open")) {
    drawer.removeClass("open")
  } else {
    drawer.addClass("open")
  }

})
