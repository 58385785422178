import Swiper from "swiper";
import { Navigation, Autoplay, EffectFade } from "swiper/modules"


const swiperHomeIntro = new Swiper('.swiper_home-intro', {
  modules: [Autoplay],
  slidesPerView: 1,
  spaceBetween: 13,
  speed: 1000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.5,
      spaceBetween: 13
    },
    768: {
      slidesPerView: 2.5,
      spaceBetween: 20
    },
    1280: {
      slidesPerView: 4.4,
      spaceBetween: 20
    }
  }
});

const swiperHomeMedia = new Swiper('.swiper_home-media', {
  modules: [Autoplay],
  slidesPerView: 1,
  spaceBetween: 13,
  speed: 4000,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  loop: true,
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 3.5,
      spaceBetween: 13
    },
    768: {
      slidesPerView: 5.5,
      spaceBetween: 20
    },
    1280: {
      slidesPerView: 8,
      spaceBetween: 20
    }
  }
});


