import $ from "jquery"

// set intro position
const intro = document.getElementById("intro") as HTMLSelectElement | null
const hero = document.getElementById("hero") as HTMLSelectElement | null

const setIntroPos = () => {
  hero && intro && (
    intro.style.top = (hero.clientHeight - 50) + "px"
  )
}

addEventListener("resize", setIntroPos)
setIntroPos()


const floatButton = document.querySelector(".float_button.pc") as HTMLElement | null
addEventListener('scroll', function(e) {
  if (!floatButton) return
  if (window.pageYOffset >= 800) {
    floatButton.style.visibility = "visible"
    floatButton.style.opacity = "1"
  } else {
    floatButton.style.visibility = "hidden"
    floatButton.style.opacity = "0"
  }
});

$(document).ready(() => {
  $("a").on("click", function(event) {
    //@ts-ignore
    if (this.hash !== "") {
      event.preventDefault();
      //@ts-ignore
      const hash = this.hash;
      const offset = $('.navigation_inner').height() || 0;

      $('html, body').animate({
        scrollTop: $(hash).offset()!.top - offset - 20
      }, 800);

      return false
    }
  });
});


