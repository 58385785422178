import gsap from "gsap";

document.addEventListener("DOMContentLoaded", () => {
  setUpAccordion();
});

const setUpAccordion = () => {
  const details = document.querySelectorAll(".faq_details");
  const IS_OPENED_CLASS = "is-opened";

  details.forEach((element) => {
    const summary = element.querySelector(".faq_summary");
    const content = element.querySelector(".faq_content");

    summary && summary.addEventListener("click", (event) => {
      event.preventDefault();

      if (element.classList.contains(IS_OPENED_CLASS)) {
        element.classList.toggle(IS_OPENED_CLASS);
        closingAnim(content, element).restart();
      } else {
        element.classList.toggle(IS_OPENED_CLASS);
        element.setAttribute("open", "true");
        openingAnim(content).restart();
      }
    });
  });
}

const closingAnim = (content: any, element: any) => gsap.to(content, {
  height: 0,
  opacity: 0,
  duration: 0.4,
  ease: "power3.out",
  overwrite: true,
  onComplete: () => {
    element.removeAttribute("open");
  },
});

const openingAnim = (content: any) => gsap.fromTo(
  content,
  {
    height: 0,
    opacity: 0,
  },
  {
    height: "auto",
    opacity: 1,
    duration: 0.4,
    ease: "power3.out",
    overwrite: true,
  });
